import { Button } from 'components/common/Button/Button';
import { ZOOM_LEVELS } from 'components/image_feed/constants';
import {
  getMinScale,
  getNewPositionWithBoundingBox,
  getNewScaleWithBoundingBox,
} from 'components/image_feed/utils';
import { useStageProvider } from 'contexts/StageProvider';
import { MinusIcon } from 'icons/MinusIcon';
import { PlusIcon } from 'icons/PlusIcon';
import { FC } from 'react';
import { cn } from 'shared/utils/cn';

export interface IImageZoomHandlerProps {
  /** A CSS class to be applied to the component root element. */
  className?: string;
  /** The total image size. */
  totalImageSize: TSize;
}

export const ImageZoomHandler: FC<IImageZoomHandlerProps> = ({
  className,
  totalImageSize,
}) => {
  const { scale, position, size, setPositionAndScale, stage } =
    useStageProvider();
  const minScale = getMinScale(size, totalImageSize);

  const handleZoomIn = () => {
    const newScale = getNewScaleWithBoundingBox(
      {
        x: scale.x / ZOOM_LEVELS.DELTA,
        y: scale.y / ZOOM_LEVELS.DELTA,
      },
      minScale
    );
    // const stageViewCursorBox = getStageViewCursorBox(totalImageSize, newScale);
    // translate to center of viewport
    const offsetPosition = {
      x: position.x - (stage?.width() || 1) / 2,
      y: position.y - (stage?.height() || 1) / 2,
    };
    const newPosition = getNewPositionWithBoundingBox(
      offsetPosition,
      newScale,
      size,
      totalImageSize
    );
    setPositionAndScale(newPosition, newScale);
  };

  const handleZoomOut = () => {
    const newScale = getNewScaleWithBoundingBox(
      {
        x: scale.x * ZOOM_LEVELS.DELTA,
        y: scale.y * ZOOM_LEVELS.DELTA,
      },
      minScale
    );
    const newPosition = getNewPositionWithBoundingBox(
      {
        x: (position.x / scale?.x) * newScale.x,
        y: (position.y / scale?.y) * newScale.y,
      },
      newScale,
      size,
      totalImageSize
    );

    setPositionAndScale(newPosition, newScale);
  };

  return (
    <div
      id="image-zoom-button-group"
      data-testid="image-zoom-button-group"
      data-scale={scale.x}
      className={cn('flex flex-col items-end gap-1', className)}
    >
      <Button
        id="image-zoom-in-button"
        aria-label="Zoom In"
        disabled={scale.x >= ZOOM_LEVELS.MAX}
        variant="tertiary"
        size="icon"
        onClick={handleZoomIn}
      >
        <PlusIcon />
      </Button>
      <Button
        id="image-zoom-out-button"
        aria-label="Zoom Out"
        disabled={scale.x <= minScale || scale.y <= minScale}
        variant="tertiary"
        size="icon"
        onClick={handleZoomOut}
      >
        <MinusIcon />
      </Button>
    </div>
  );
};
