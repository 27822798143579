import { StylesProvider, ThemeProvider } from '@material-ui/core/styles';
import * as Sentry from '@sentry/react';
import 'array.prototype.tosorted';
import { Amplify } from 'aws-amplify';
import { CenteredLoader } from 'components/common/CenteredLoader';
import { ToastContainer } from 'components/common/Toast/Toast';
import { ErrorBoundary } from 'components/error/ErrorBoundary';
import { ApiProvider } from 'contexts/ApiProvider';
import { AppConfigProvider } from 'contexts/AppConfigProvider';
import { AuthProvider, useAuth } from 'contexts/AuthProvider';
import { TypeConfigProvider } from 'contexts/TypeConfigProvider/TypeConfigProvider';
import {
  URLStoreProvider,
  useURL,
} from 'contexts/URLStoreProvider/URLStoreProvider';
import { useInitializeUsers } from 'hooks/useInitializeUsers';
import { useUserPermissions } from 'hooks/useUserPermissions';
import { FC, Suspense, useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { setupSentry } from 'setupSentry';
import { initializeAnalyticsService } from 'shared/analytics/AnalyticsService';
import { SetupAnalytics } from 'shared/analytics/SetupAnalytics';
import { AwsAmplifyConfig } from 'shared/config/AwsAmplifyConfig';
import { createAppConfig } from 'shared/config/create-app-config';
import { EAuthStatus } from 'shared/interfaces/auth';
import { UserWithMetadata } from 'shared/interfaces/user';
import { AugmentedRouteObject, RouteTree, getFlattenedRoutes } from './Routes';
import { theme } from './theme';

declare global {
  interface Window {
    NEATLEAF_APP_CONFIG: Record<string, string>;
  }
}

/**
 * Return the routes for the application.
 * @returns {JSX.Element} App component
 */
const App: FC = Sentry.withProfiler(() => {
  useInitializeUsers();
  const {
    authStatus,
    organizations,
    currentlySelectedOrganization,
    user,
    everLoaded,
    isNeatleafOrganizationMember,
  } = useAuth();
  const { isLoginPage, navigateToLogin, navigateToHome, skipAuthCheck } =
    useURL();
  const { permissionContainer } = useUserPermissions();

  // Since we have redirection logic that depends on user permissions,
  // we need to wait for the user permissions to load before rendering the app.
  const ready =
    authStatus === EAuthStatus.LOGGED_OUT ||
    (organizations.length === 0 && everLoaded) ||
    (!!user && !!currentlySelectedOrganization && !!permissionContainer);

  const flattenedRoutes = getFlattenedRoutes();

  useEffect(() => {
    if (skipAuthCheck(flattenedRoutes as AugmentedRouteObject[])) {
      return;
    }

    if (authStatus === EAuthStatus.LOGGED_OUT && !isLoginPage) {
      navigateToLogin();
    } else if (authStatus === EAuthStatus.LOGGED_IN && isLoginPage) {
      navigateToHome();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authStatus, flattenedRoutes]);

  if (!ready) return <CenteredLoader />;

  return (
    <div
      id="app"
      className="relative flex flex-col gap-2 lg:gap-4 w-full h-svh overflow-x-hidden bg-sand-200"
    >
      <SetupAnalytics
        flattenedRoutes={flattenedRoutes}
        user={user as UserWithMetadata}
        isNeatleafOrganizationMember={isNeatleafOrganizationMember}
        organization={currentlySelectedOrganization}
        searchParamsKeysThatShouldBeTracked={[
          'signal-ids',
          'gradient-type',
          'image-label-code',
          'image-type',
          'image-view-type',
          'showGridInfo',
          'show-cultivars',
          'edit-cultivars',
        ]}
      />
      <RouteTree />
    </div>
  );
});

const WithProviders: FC = () => {
  const appConfig = createAppConfig(window.NEATLEAF_APP_CONFIG);
  setupSentry(appConfig);

  const awsAmplifyConfig: AwsAmplifyConfig = {
    aws_cognito_identity_pool_id: appConfig.aws.cognito.identityPoolId,
    aws_cognito_region: appConfig.aws.cognito.region,
    aws_project_region: appConfig.aws.logging.projectRegion,
    aws_user_pools_id: appConfig.aws.cognito.userPoolId,
    aws_user_pools_web_client_id: appConfig.aws.cognito.webClientId,
  };
  Amplify.configure(awsAmplifyConfig);
  initializeAnalyticsService(
    appConfig.mixpanel,
    appConfig.environment,
    appConfig.release
  );

  return (
    <AppConfigProvider appConfig={appConfig}>
      <ApiProvider appConfig={appConfig}>
        <StylesProvider>
          <ThemeProvider theme={theme}>
            <ErrorBoundary>
              <AuthProvider>
                {({ user }) => (
                  <TypeConfigProvider
                    preferredUnits={user?.metadata?.preferred_units}
                  >
                    <Suspense fallback={<CenteredLoader />}>
                      <BrowserRouter>
                        <URLStoreProvider>
                          <App />
                        </URLStoreProvider>
                      </BrowserRouter>
                    </Suspense>
                    <ToastContainer />
                  </TypeConfigProvider>
                )}
              </AuthProvider>
            </ErrorBoundary>
          </ThemeProvider>
        </StylesProvider>
      </ApiProvider>
    </AppConfigProvider>
  );
};

export default WithProviders;
