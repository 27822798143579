import { Button } from 'components/common/Button/Button';
import { useScreenSize } from 'hooks/useScreenSize';
import { ChevronBackwardIcon } from 'icons/ChevronBackwardIcon';
import { ChevronForwardIcon } from 'icons/ChevronForwardIcon';
import { ComponentProps, forwardRef } from 'react';
import { cn } from 'shared/utils/cn';

interface VerticalDividerProps extends ComponentProps<'div'> {
  open: boolean;
  side?: 'left' | 'right';
}

export const VerticalDivider = forwardRef<HTMLDivElement, VerticalDividerProps>(
  function VerticalDivider(
    { className, open, title, side = 'left', ...props },
    ref
  ) {
    const { isMobile } = useScreenSize();
    const BackwardIcon =
      side === 'left' ? ChevronBackwardIcon : ChevronForwardIcon;
    const ForwardIcon =
      side === 'left' ? ChevronForwardIcon : ChevronBackwardIcon;

    if (isMobile) {
      return null;
    }

    return (
      <div
        ref={ref}
        {...props}
        className={cn(
          'group/divider min-w-4 mx-4',
          side === 'left' && ['cursor-w-resize', !open && 'cursor-e-resize'],
          side === 'right' && ['cursor-e-resize', !open && 'cursor-w-resize'],
          className
        )}
      >
        <div
          className={cn(
            'relative h-[calc(100%-4px)] w-1 mx-auto hidden',
            'group-hover/divider:bg-neutral-200 group-hover/divider:block',
            !open && 'block'
          )}
        >
          <Button
            size="icon"
            variant="secondary"
            aria-label={`${open ? 'Collapse' : 'Expand'} ${title}`}
            className="absolute left-1/2 transform -translate-x-1/2"
          >
            {open ? <BackwardIcon /> : <ForwardIcon />}
          </Button>
        </div>
      </div>
    );
  }
);
