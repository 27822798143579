import { useGrowthCyclesByDates } from 'api/growth-cycle';
import { Button } from 'components/common/Button/Button';
import { CircularProgress } from 'components/common/CircularProgress/CircularProgress';
import { TimelineRange } from 'components/common/TimelineRange/TimelineRange';
import { Wrap } from 'components/common/Wrap/Wrap';
import { DiscussionBoundingBoxAnnotations } from 'components/discussions/DiscussionBoundingBoxAnnotations';
import { NoDataView } from 'components/growth_cycle/NoDataView';
import { useTypeConfig } from 'contexts/TypeConfigProvider/TypeConfigProvider';
import {
  useHeatMapURL,
  useZoneDetailsPageURL,
} from 'contexts/URLStoreProvider/URLStoreProvider';
import { useCurrentZone } from 'hooks/useCurrentZone';
import { useDrawBoundingBoxController } from 'hooks/useDrawBoundingBoxController';
import { usePermissions } from 'hooks/usePermissions';
import { useSignals } from 'hooks/useSignals';
import { CloseIcon } from 'icons/CloseIcon';
import { CommentIcon } from 'icons/CommentIcon';
import isNil from 'lodash.isnil';
import { PlotRelayoutEvent } from 'plotly.js';
import Plotly from 'plotly.js-cartesian-dist-min';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import createPlotlyComponent from 'react-plotly.js/factory';
import { useResizeDetector } from 'react-resize-detector';
import { TDiscussionMetadata } from 'shared/interfaces/discussion';
import { EAggregationTypes, EGradientTypes } from 'shared/interfaces/heatmap';
import { EMeasurementTypes } from 'shared/interfaces/measurement';
import { HeatMapGradient } from './HeatMapGradient';
import { HeatMapTypeSelection } from './HeatMapTypeSelection';
import { useHeatMap } from './hooks/useHeatMap';
import { useHeatMapPrefetch } from './hooks/useHeatMapPrefetch';
import { PLOT_CONFIG } from './utils';

const { AGGREGATED } = EAggregationTypes;
const { MICROCLIMATES, OPTIMAL_RANGE } = EGradientTypes;
const { AirLeafTemperatureDifference } = EMeasurementTypes;

const Plot = createPlotlyComponent(Plotly);

/**
 * Renders heatmap of a given measurement run.
 */
export const HeatMap: FC = () => {
  const {
    height: heatMapHeight,
    width: heatMapWidth,
    ref: heatMapReference,
  } = useResizeDetector();
  const { heatmapSignals, presetTypes } = useTypeConfig();
  const { signals } = useSignals();
  const selectedSignal = signals.length > 0 ? signals[0] : heatmapSignals[0]!;
  const typeConfig = useMemo(() => {
    if (
      !selectedSignal ||
      !heatmapSignals.some(({ type }) => type === selectedSignal.type)
    ) {
      return heatmapSignals[0]!;
    }

    return selectedSignal;
  }, [heatmapSignals, selectedSignal]);

  const { currentZone, zoneTimeZone, currentTimeInCurrentZone } =
    useCurrentZone();

  const {
    getRangeStartTime,
    getRangeEndTime,
    getMeasurementRunStartTime,
    setMeasurementRunStartTime,
  } = useZoneDetailsPageURL();

  const {
    setAggregationType,
    setSignalIds,
    setGradientType,
    setRange,
    setShowComments,
    showComments,
    aggregationType,
    gradientType,
  } = useHeatMapURL();

  const localAggregationType = useMemo(() => {
    if (typeConfig.hideHeatmapSingle) {
      return AGGREGATED;
    }
    return aggregationType;
  }, [aggregationType, typeConfig.hideHeatmapSingle]);

  const handleSelectDate = useCallback(
    (date: Date) => {
      setMeasurementRunStartTime({
        zonedDate: date.valueOf(),
        timeZone: zoneTimeZone,
      });
    },
    [setMeasurementRunStartTime, zoneTimeZone]
  );

  const startTime = getRangeStartTime(zoneTimeZone)!;
  const endTime = getRangeEndTime(zoneTimeZone)!;

  const { growthCycle } = useGrowthCyclesByDates({
    zoneId: currentZone?.id,
    start: new Date(startTime),
    end: new Date(endTime),
    zoneTimeZone,
    presetTypes,
  });

  const {
    heatMapPlotInformation,
    heatMapScale,
    frameList,
    currentFrame,
    gradientScaleValues,
    loading,
    heatMapId,
  } = useHeatMap({
    typeConfig,
    selectedTime: new Date(
      getMeasurementRunStartTime(zoneTimeZone) || currentTimeInCurrentZone
    ),
    gradientType,
    aggregationType: localAggregationType,
    width: heatMapWidth ?? 0,
    height: heatMapHeight ?? 0,
    zoneId: currentZone?.id,
    startTime,
    endTime,
    growthCycle,
  });

  const timelineDates = useMemo(
    () => frameList.map(({ midTime }) => midTime),
    [frameList]
  );
  const heatMapIdList = useMemo(
    () => frameList.map(({ heatMapId }) => heatMapId!),
    [frameList]
  );
  const heatMapIndex = heatMapIdList.findIndex((id) => id === heatMapId);
  // Prefetch next heatmap.
  useHeatMapPrefetch({
    heatMapIdList,
    typeConfig,
    index: heatMapIndex,
    aggregationType: localAggregationType,
  });

  const hasOptimalRange =
    !isNil(heatMapScale.optimalRangeLowerBound) &&
    !isNil(heatMapScale.optimalRangeUpperBound);

  const resolution = useMemo(() => {
    if (
      !(
        heatMapPlotInformation &&
        !isNil(heatMapPlotInformation!.layout.width) &&
        !isNil(heatMapPlotInformation!.layout.height)
      )
    ) {
      return null;
    }
    return {
      width: heatMapPlotInformation.layout.width,
      height: heatMapPlotInformation.layout.height,
    };
  }, [heatMapPlotInformation]);

  const handleRelayout = useCallback(
    (event: Readonly<PlotRelayoutEvent>) => {
      if (event.autosize) return;
      const xRange0 = event['xaxis.range[0]'];
      const xRange1 = event['xaxis.range[1]'];
      const yRange0 = event['yaxis.range[0]'];
      const yRange1 = event['yaxis.range[1]'];
      if (
        isNil(xRange0) ||
        isNil(xRange1) ||
        isNil(yRange0) ||
        isNil(yRange1)
      ) {
        setRange(undefined);
      } else {
        const xRange = [xRange0, xRange1];
        const yRange = [yRange0, yRange1];
        setRange([...xRange, ...yRange]);
      }
    },
    [setRange]
  );

  useEffect(() => {
    if (selectedSignal?.type !== typeConfig.type) {
      setSignalIds([typeConfig.type], { replace: true });
    }
  }, [selectedSignal?.type, setSignalIds, typeConfig.type]);

  useEffect(() => {
    if (!typeConfig.hasOptimalRange && gradientType === OPTIMAL_RANGE) {
      setGradientType(MICROCLIMATES, { replace: true });
    }
  }, [gradientType, setGradientType, typeConfig.hasOptimalRange]);

  useEffect(() => {
    if (!typeConfig.heatMapCodes) {
      // if measurement type does not support heat map, use default
      setSignalIds([AirLeafTemperatureDifference], { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [typeConfig.heatMapCodes]);

  useEffect(() => {
    if (typeConfig.hideHeatmapSingle && aggregationType !== AGGREGATED) {
      // if measurement type does not support heat map, use default
      setAggregationType(AGGREGATED, { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [aggregationType, typeConfig.hideHeatmapSingle]);

  const [isDrawAnnotationMode, setIsDrawAnnotationMode] = useState(false);
  const handleDrawEnd = useCallback(() => {
    setIsDrawAnnotationMode(false);
  }, []);
  const drawBoundingBoxController =
    useDrawBoundingBoxController<TDiscussionMetadata>({
      scaleAndPosition: {
        scale: 1,
        positionX: 0,
        positionY: 0,
      },
      drawingMode: isDrawAnnotationMode,
      onDrawEnd: () => handleDrawEnd(),
    });
  const { discussions } = usePermissions();

  const showAnnotations =
    discussions.canView && showComments && currentFrame && resolution;

  return (
    <>
      <div className="h-full flex flex-col justify-between gap-2 sm:4">
        <HeatMapTypeSelection
          typeConfig={typeConfig}
          aggregationType={localAggregationType}
          hasOptimalRange={hasOptimalRange}
        />

        <div
          className="h-full w-full flex items-center justify-center min-h-48 relative"
          ref={heatMapReference}
          role="figure"
          aria-label="heat map"
        >
          {heatMapPlotInformation && (
            <Wrap
              with={(children) => (
                <DiscussionBoundingBoxAnnotations
                  annotationInfo={{
                    startTime: currentFrame!.startTime,
                    endTime: currentFrame!.endTime,
                    id: currentFrame!.heatMapId,
                    type:
                      localAggregationType === AGGREGATED
                        ? 'heatmap_aggregate_annotation'
                        : 'heatmap_annotation',
                    measurementType: typeConfig.type,
                    gradientType,
                  }}
                  resolution={resolution!}
                  drawController={drawBoundingBoxController}
                  onSelectionChange={(_element) => {
                    // @TODO: handle selection change if needed (is it?)
                    console.debug('onSelectionChange from HeatMap');
                  }}
                >
                  {children}
                </DiscussionBoundingBoxAnnotations>
              )}
              condition={!!showAnnotations}
            >
              <Plot
                className="!flex h-full items-center justify-center"
                config={{
                  ...PLOT_CONFIG,
                  staticPlot: isDrawAnnotationMode,
                }}
                data={[heatMapPlotInformation.data]}
                layout={heatMapPlotInformation.layout}
                revision={heatMapIndex}
                useResizeHandler={true}
                onRelayout={handleRelayout}
              />
            </Wrap>
          )}

          {!heatMapPlotInformation && frameList.length === 0 && !loading && (
            <NoDataView />
          )}

          {!heatMapPlotInformation && loading && <CircularProgress />}

          {discussions.canCreate && showComments && (
            <Button
              disabled={!!drawBoundingBoxController?.disabled}
              onClick={() => {
                setIsDrawAnnotationMode(!isDrawAnnotationMode);
                setShowComments(true);
              }}
              variant={isDrawAnnotationMode ? 'secondary' : 'primary'}
              size="responsive"
              className="absolute top-0 right-0"
              leadingIcon={
                isDrawAnnotationMode ? <CloseIcon /> : <CommentIcon />
              }
            >
              {isDrawAnnotationMode ? 'Close' : 'Comment'}
            </Button>
          )}
        </div>

        <HeatMapGradient
          gradientType={gradientType}
          heatMapScale={heatMapScale}
          typeConfig={typeConfig}
          scaleValues={gradientScaleValues}
          visible={!!heatMapPlotInformation}
        />
      </div>

      <TimelineRange dates={timelineDates} onSelectDate={handleSelectDate} />
    </>
  );
};
