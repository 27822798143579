import { createStyles, makeStyles } from '@material-ui/core';
import {
  NotificationTarget,
  NotificationType,
  useCreateUserNotificationSettingMutation,
} from 'api/user-notification-settings';
import { Auth } from 'aws-amplify';
import { Typography } from 'components/common/Typography';
import {
  EditProfileForm,
  IEditProfileForm,
} from 'components/user/EditProfileForm';
import { useAuth } from 'contexts/AuthProvider';
import { useSettingsURL } from 'contexts/URLStoreProvider/URLStoreProvider';
import {
  useCreateUserMutation,
  useRegisterUserToOrganizationWithRoleMutation,
} from 'graphql/generated/react_apollo';
import { useUsers } from 'hooks/useUsers';
import isNil from 'lodash.isnil';
import { FC, useCallback } from 'react';
import { ESettingsTabs } from 'shared/interfaces/settings';

/**
 * Sign up a new user.
 *
 * @param {string} username User name
 * @param {string} password Password
 * @param {string} email Email
 * @param {string} phoneNumber Phone number
 * @param {string} givenName Given name
 * @param {string} familyName Family name
 */
async function cognitoSignUp(
  username: string,
  password: string,
  email: string,
  phoneNumber: string,
  givenName: string,
  familyName: string
) {
  return await Auth.signUp({
    username,
    password,
    attributes: {
      email, // optional
      given_name: givenName,
      family_name: familyName,
      phone_number: `+1${phoneNumber}`,
    },
    autoSignIn: {
      enabled: true,
    },
  });
}
//

const useStyles = makeStyles(({ spacing }) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      gap: spacing(1),
      width: '100%',
    },
  })
);

const defaultValues: IEditProfileForm = {
  active: true,
  email: '',
  firstName: '',
  lastName: '',
  phoneNumber: '',
  roles: [],
};

const DEFAULT_NOTIFICATIONS = [NotificationType.InsightReport];

export const UserAdd: FC = () => {
  const classes = useStyles();
  const { navigateToTab } = useSettingsURL();

  const { currentlySelectedOrganization } = useAuth();

  const { refetchUserList, userRolesByCode } = useUsers();

  const [createUser] = useCreateUserMutation();
  const [registerUserToOrganizationWithRole] =
    useRegisterUserToOrganizationWithRoleMutation();

  const handleCancelEdit = useCallback(() => {
    navigateToTab(ESettingsTabs.USERS);
  }, [navigateToTab]);

  const { mutateAsync: createUserNotificationSetting } =
    useCreateUserNotificationSettingMutation();

  const optInForNotifications = useCallback(
    async (userId: number) => {
      if (!currentlySelectedOrganization) {
        return [];
      }

      return DEFAULT_NOTIFICATIONS.map((notificationType) => {
        return createUserNotificationSetting({
          setting: {
            zoneUid: null,
            userId: userId.toString(),
            notificationType,
            target: NotificationTarget.Email,
            organizationCode: currentlySelectedOrganization?.code,
            locationId: null,
          },
          userId: userId.toString(),
        });
      });
    },
    [createUserNotificationSetting, currentlySelectedOrganization]
  );

  const handleSubmit = useCallback(
    async (data: IEditProfileForm) => {
      if (isNil(currentlySelectedOrganization)) {
        return;
      }

      try {
        // TODO - update this later to use non-static password: NL-4395
        const password = import.meta.env.VITE_INITIAL_PASSWORD ?? '';
        data.email = data.email.toLowerCase();
        await cognitoSignUp(
          data.email,
          password,
          data.email,
          data.phoneNumber,
          data.firstName,
          data.lastName
        );

        const { data: newUser } = await createUser({
          variables: {
            active: data.active,
            email: data.email,
            firstName: data.firstName,
            lastName: data.lastName,
            password: password,
            phoneNumber: data.phoneNumber,
          },
        });
        const userId = newUser?.insert_user?.returning?.[0]?.id;

        if (!userId) {
          throw new Error('Failed to create a user');
        }
        const registerPromises = data.roles.map((role) =>
          registerUserToOrganizationWithRole({
            variables: {
              userId,
              organizationId: currentlySelectedOrganization.id,
              roleId: userRolesByCode[role]!.id,
            },
          })
        );
        const upsertPromises = await optInForNotifications(userId);
        await Promise.all([...upsertPromises, ...registerPromises]);
        await refetchUserList();
        navigateToTab(ESettingsTabs.USERS);
      } catch (error) {
        throw new Error(`Failed to create a user: ${(error as Error).message}`);
      }
    },
    [
      currentlySelectedOrganization,
      createUser,
      optInForNotifications,
      refetchUserList,
      navigateToTab,
      registerUserToOrganizationWithRole,
      userRolesByCode,
    ]
  );

  return (
    <div className={classes.container}>
      <Typography variant="h6">Add User</Typography>

      <EditProfileForm
        defaultValues={defaultValues}
        disabledEmail={false}
        onCancel={handleCancelEdit}
        onSubmit={handleSubmit}
        onCloseSuccessMessage={handleCancelEdit}
        showRole
        showStatus
        successMessage="User is successfully created!"
        submitButtonText="Add User"
      />
    </div>
  );
};
