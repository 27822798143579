import {
  ComponentPropsWithoutRef,
  MouseEventHandler,
  ReactElement,
  ReactNode,
  Ref,
  forwardRef,
} from 'react';

import { cn } from 'shared/utils/cn';
import { Label, LabelProps } from '../Label/Label';
import { StatusText } from '../StatusText/StatusText';

export type InputProps = ComponentPropsWithoutRef<'input'> & {
  /** The input value to set  */
  value?: string;
  /** An optional status text displayed below the field  */
  helperText?: ReactNode;
  /** An optional status text displayed below the field  */
  errorText?: ReactNode;
  /** The reference div that wraps the input element   */
  inputContainerRef?: Ref<HTMLDivElement>;
  /** An optional field label */
  label?: LabelProps['value'];
  /** Sets the placement of the label. Defaults to `left` */
  labelPlacement?: LabelProps['placement'];
  /** Sets the className of the label. */
  labelClassName?: LabelProps['className'];
  /** An optional element displayed within the input */
  actionIcon?: ReactElement<HTMLElement>;
  /** Ensure that `actionIcon` is defined when `onClickAction` is also defined */
  onClickAction?: MouseEventHandler<HTMLButtonElement>;
};

/**
 * Use a text `Input` when the expected user input is a single line of text.
 * Text inputs have a fixed height and are used to enter simple free-form data entries
 * with any combination of letters, numbers, or symbols.
 */
export const Input = forwardRef<HTMLInputElement, InputProps>(function Input(
  {
    value,
    disabled,
    readOnly,
    helperText,
    errorText,
    actionIcon,
    inputContainerRef,
    className,
    label,
    labelPlacement = 'left',
    labelClassName,
    'aria-label': ariaLabel,
    onClickAction,
    ...props
  },
  ref
) {
  return (
    <fieldset className="group/input flex w-full flex-col gap-1">
      <Label
        value={label}
        placement={labelPlacement}
        disabled={disabled}
        className={cn('w-full', labelClassName)}
      >
        <div
          className={cn('relative w-full', actionIcon && '@container/input')}
          ref={inputContainerRef}
        >
          <input
            ref={ref}
            value={value}
            disabled={disabled}
            readOnly={readOnly}
            aria-label={
              ariaLabel ? ariaLabel : typeof label === 'string' ? label : ''
            }
            className={cn(
              'text-base font-normal',
              'w-full h-10 truncate rounded-sm border border-sand-500 bg-white py-1 px-2 text-gray-900 outline-none',
              'hover:border-sand-800 hover:disabled:border-sand-500',
              'focus:border-orange-400',
              'disabled:bg-sand-200 disabled:text-sand-700 disabled:border-sand-500',
              'placeholder:text-gray-500',
              'read-only:bg-white read-only:text-gray-500 read-only:border-sand-300 read-only:hover:border-sand-300 group-has-[button:hover]/input:read-only:border-sand-300 group-has-[button:active]/input:read-only:border-sand-300',
              'group-has-[button:active]/input:border group-has-[button:active]/input:border-orange-400',
              'invalid:bg-red-100 invalid:border-red-300 invalid:hover:border-red-500 invalid:focus:border-red-500',
              actionIcon && '@[112px]/input:pr-10',
              className
            )}
            {...props}
          />

          {actionIcon && (
            <button
              disabled={disabled || readOnly}
              onClick={onClickAction}
              className={cn(
                'absolute inset-y-1 right-1 p-1 flex items-center rounded-xs bg-transparent text-gray-800 outline-none',
                'hover:bg-sand-400',
                'disabled:m-[2px] disabled:hover:bg-transparent',
                'hidden @[112px]/input:flex'
              )}
            >
              {actionIcon}
            </button>
          )}
        </div>
      </Label>
      {helperText && (
        <StatusText className="group-valid/input:block">
          {helperText}
        </StatusText>
      )}
      {errorText && (
        <StatusText className="text-red-500 group-invalid/input:block">
          {errorText}
        </StatusText>
      )}
    </fieldset>
  );
});
