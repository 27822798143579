import { Button } from 'components/common/Button/Button';
import { Radio } from 'components/common/Radio/Radio';
import { Tooltip } from 'components/common/Tooltip/Tooltip';
import { useLineChartURL } from 'contexts/URLStoreProvider/URLStoreProvider';
import { useScreenSize } from 'hooks/useScreenSize';
import { useSignals } from 'hooks/useSignals';
import { ComponentProps, forwardRef, useMemo } from 'react';
import { MeasurementAggregation } from 'shared/interfaces/measurement';
import { cn } from 'shared/utils/cn';
import { SeriesToggle } from './SeriesToggle';

const { AVERAGE, CONTINUOUS } = MeasurementAggregation;

const viewTypeTooltip = `Continuous line chart is only enabled for time frame windows less than or equal to 7 days`;

interface ToolbarProps extends ComponentProps<'div'> {
  disabledAggregation: boolean;
  onToggleSeries: ComponentProps<typeof SeriesToggle>['onToggleSeries'];
}

export const Toolbar = forwardRef<HTMLDivElement, ToolbarProps>(
  function Toolbar(
    { className, disabledAggregation, onToggleSeries, ...props },
    ref
  ) {
    const { isMobile } = useScreenSize();
    const { signals } = useSignals();
    const { viewType, setViewType, showSummary, setShowSummary } =
      useLineChartURL();
    const multipleAggregationsAvailable = useMemo(
      () => signals.every((signal) => signal.hasMultipleAggregations),
      [signals]
    );

    return (
      <div
        {...props}
        ref={ref}
        className={cn('flex gap-2 flex-wrap items-center', className)}
      >
        <SeriesToggle onToggleSeries={onToggleSeries} />

        <div className="relative ml-auto flex gap-2">
          {multipleAggregationsAvailable && (
            <Tooltip label={viewTypeTooltip}>
              <Radio.Group
                value={viewType}
                orientation="horizontal"
                disabled={disabledAggregation}
                onChange={(e) =>
                  setViewType(e.target.value as MeasurementAggregation)
                }
              >
                <Radio.Input value={AVERAGE} label={AVERAGE} />
                <Radio.Input value={CONTINUOUS} label={CONTINUOUS} />
              </Radio.Group>
            </Tooltip>
          )}

          <div id="slot-chart-filters" className="flex gap-2" />

          {!isMobile && (
            <Button
              variant="secondary"
              role="checkbox"
              selected={showSummary}
              disabled={signals.length === 0}
              onClick={() => {
                setShowSummary(!showSummary);
              }}
              className="select-none"
            >
              Show summary
            </Button>
          )}
        </div>
      </div>
    );
  }
);
