import {
  Children,
  cloneElement,
  ComponentPropsWithoutRef,
  forwardRef,
  ReactElement,
  ReactNode,
} from 'react';

import { cn } from 'shared/utils/cn';
import { Label, type LabelProps } from '../Label/Label';
import { StatusText } from '../StatusText/StatusText';

interface RadioInputProps extends ComponentPropsWithoutRef<'input'> {
  /**
   * Sets a label on the radio. When set the radio is wrapped in a HTML label element.
   * When not set the Radio is rendered without a HTML label element */
  label: LabelProps['value'];
  /**
   * Sets the placement of the radio label.
   * Defaults to `right` when variant is `flat` or `right` when variant is `flat`.
   */
  labelPlacement?: LabelProps['placement'];
  /** Sets the className of the label. */
  labelClassName?: LabelProps['className'];
  /** An optional status text displayed below the field  */
  statusText?: React.ReactNode;
}

/** Radio button is rendered as a small circle and highlighted when selected. */
const Input = forwardRef<HTMLInputElement, RadioInputProps>(function Input(
  {
    label,
    labelPlacement = 'right',
    labelClassName,
    className,
    id,
    disabled,
    ...props
  },
  ref
) {
  return (
    <Label
      value={label}
      placement={labelPlacement}
      disabled={disabled}
      className={cn(
        'py-1 px-2 font-normal text-neutral-900 rounded-xs w-full',
        'hover:bg-sand-300 active:bg-sand-500',
        'has-[:focus]:outline outline-[1.5px] outline-orange-600 -outline-offset-[1.5px]',
        'group-has-[:disabled]/radio:bg-transparent',
        labelClassName
      )}
    >
      <input
        ref={ref}
        {...props}
        id={id}
        disabled={disabled}
        role="radio"
        type="radio"
        className={cn(
          'relative w-4 appearance-none rounded-full bg-transparent outline-none',
          'before:absolute before:left-0 before:-top-2 before:h-4 before:w-4 before:rounded-md before:border',
          'after:left-1 after:-top-1 after:h-2 after:w-2 after:rounded-sm',
          'before:border-gray-900 before:bg-transparent before:active:bg-sand-500 after:bg-gray-900',
          'checked:before:border-neutral-900 checked:after:absolute',
          'disabled:bg-gray-400 disabled:bg-transparent disabled:before:border-gray-400',
          'disabled:before:transparent disabled:after:bg-gray-400',
          'invalid:before:border-red-500 invalid:before:bg-red-100 invalid:after:bg-red-500',
          'invalid:checked:before:border-red-500 invalid:before:hover:bg-red-100',
          className
        )}
      />
    </Label>
  );
});

type RadioGroupProps = Omit<
  RadioInputProps,
  'label' | 'checked' | 'defaultChecked' | 'value' | 'defaultValue' | 'children'
> & {
  children: ReactElement<RadioInputProps>[];
  /** Sets the orientation of the child radio inputs */
  orientation?: 'horizontal' | 'vertical';
  /** An optional status text displayed below the field  */
  helperText?: ReactNode;
  /** An optional status text displayed below the field  */
  errorText?: ReactNode;
  /** An optional field label */
  label?: LabelProps['value'];
  /** Sets the placement of the label. Defaults to `top` */
  labelPlacement?: LabelProps['placement'];
  /** Sets the className of the label. */
  labelClassName?: LabelProps['className'];
} & Exclusive<
    /** `defaultValue` and `value` are mutually exclusive */
    {
      /**
       * Sets an initial value on the group. In keeping with defaultChecked on radio inputs.
       * Best for use when the component is uncontrolled - for controlled components use `value` prop
       */
      defaultValue?: string;
    },
    {
      /**
       * Sets a value on the group. In keeping with checked on radio inputs.
       * Best for use when the component is controlled - for uncontrolled components use `defaultValue` prop
       */
      value?: string;
    }
  >;

/**
 * A radio group is a set of checkable buttons, known as radio buttons,
 * where no more than one of the buttons can be checked at a time.
 */
const Group = forwardRef<HTMLDivElement, RadioGroupProps>(function Group(
  {
    className,
    children,
    orientation = 'vertical',
    defaultValue,
    value,
    id,
    helperText,
    errorText,
    label,
    labelPlacement = 'top',
    labelClassName,
    ...props
  },
  ref
) {
  return (
    <fieldset className={cn('group/radio flex flex-col gap-1 justify-center')}>
      <Label
        as="div"
        value={label}
        placement={labelPlacement}
        className={labelClassName}
      >
        <div
          id={id}
          ref={ref}
          className={cn(
            'flex gap-1',
            orientation === 'vertical' ? 'flex-col' : 'flex-row',
            className
          )}
          aria-label={typeof label === 'string' ? label : undefined}
          role="radiogroup"
        >
          {Children.map(children, (child: React.ReactElement) => {
            const checkedProps = {
              defaultChecked:
                defaultValue && child.props.value === defaultValue
                  ? true
                  : undefined,
              checked:
                value !== undefined ? child.props.value === value : undefined,
            };

            return cloneElement(child, {
              ...props,
              ...checkedProps,
              ...child.props,
            });
          })}
        </div>
      </Label>
      {helperText && (
        <StatusText className="group-valid/radio:block">
          {helperText}
        </StatusText>
      )}
      {errorText && (
        <StatusText className="text-red-500 group-invalid/radio:block">
          {errorText}
        </StatusText>
      )}
    </fieldset>
  );
});

export const Radio = {
  Group,
  Input,
};
