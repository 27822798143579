import { Button } from 'components/common/Button/Button';
import { StatusText } from 'components/common/StatusText/StatusText';
import { TimePicker } from 'components/common/TimePicker';
import { GarbageIcon } from 'icons/GarbageIcon';
import { LockIcon } from 'icons/LockIcon';
import isNil from 'lodash.isnil';
import { ComponentPropsWithoutRef, forwardRef, useRef } from 'react';
import { mergeRefs } from 'react-merge-refs';
import { LightCycle, LightCyclesPreset } from 'shared/interfaces/growthCycle';
import { cn } from 'shared/utils/cn';
import { getEmptyLightCycle } from 'shared/utils/growthCycle';
import { PresetName } from './PresetName';
import { useWarningSharedPreset } from './hooks/useWarningSharedPreset';

type ManagedPresetFields = keyof Pick<
  LightCyclesPreset,
  'name' | 'lightCycles'
>;

type ManagedFields = keyof Omit<LightCycle, 'id'>;

export interface EditPresetLightCyclesProps
  extends Omit<ComponentPropsWithoutRef<'div'>, 'onChange'> {
  /** Determines where the preset name input is placed */
  namePlacement?: 'top' | 'bottom';
  /** An optional subtitle  */
  subtitle?: string;
  /** The preset to manage */
  preset: LightCyclesPreset;
  /** The total days of the growth cycle */
  totalDays: number;
  /** Called whenever the preset is updated */
  onChange: (preset: LightCyclesPreset) => void;
}

/**
 * List, add, edit, and remove light cycles.
 */
export const EditPresetLightCycles = forwardRef<
  HTMLDivElement,
  EditPresetLightCyclesProps
>(function EditPresetLightCycles(
  {
    namePlacement = 'top',
    className,
    title,
    subtitle,
    preset,
    totalDays,
    onChange,
    ...props
  },
  ref
) {
  useWarningSharedPreset(preset);

  const lightCycles =
    preset.lightCycles.length > 0 ? preset.lightCycles : [getEmptyLightCycle()];

  // Disable Add button when there are entries with values
  const disableAddButton =
    lightCycles.length === 0 || lightCycles.some((lc) => lc.duration === 0);

  const handleChangePreset = (
    fieldName: ManagedPresetFields,
    value: LightCyclesPreset['name'] | LightCyclesPreset['lightCycles']
  ) => {
    onChange({ ...preset, [fieldName]: value });
  };
  const handleAddRow = () => {
    const emptylightCycle = getEmptyLightCycle();
    const lastlightCycle = {
      ...(lightCycles[lightCycles.length - 1] || emptylightCycle),
      id: emptylightCycle!.id,
    };
    handleChangePreset('lightCycles', [
      ...lightCycles,
      { ...lastlightCycle, start: lastlightCycle.start + 7 },
    ]);
    requestAnimationFrame(() => {
      lastStartInputRef.current?.focus();
    });
  };
  const handleDelete = (id: LightCycle['id']) => () => {
    handleChangePreset(
      'lightCycles',
      lightCycles.filter((lc) => lc.id !== id)
    );
  };
  const handleClearAll = () => {
    handleChangePreset('lightCycles', [getEmptyLightCycle()]);
  };
  const handleChange =
    (id: LightCycle['id'], fieldName: ManagedFields) => (value: string) => {
      let transformedValue: string | number = value;
      if (fieldName === 'start') {
        const [weeks, days] = value.split(':').map(Number) as [number, number];
        transformedValue = days + weeks * 7;
      } else if (fieldName === 'duration') {
        const [hours, minutes] = value.split(':').map(Number) as [
          number,
          number,
        ];
        transformedValue = hours * 60 + minutes;
      }
      const updatedLightCycles = lightCycles.map((target) =>
        target.id === id ? { ...target, [fieldName]: transformedValue } : target
      );
      handleChangePreset('lightCycles', updatedLightCycles);
    };

  const handleWeekAndDaysRef = (
    element: Nullable<HTMLInputElement>,
    isFirst: boolean
  ) => {
    if (isNil(element?.value)) {
      return;
    }
    const [weeks, days] = element.value
      .replace(/(\d+)w (\d+)d/, '$1:$2')
      .split(':')
      .map((value) => Number(value) - 1) as [number, number];
    const selectedDay = weeks * 7 + days;
    const startTimeNotUnique =
      lightCycles.find(
        (lc) => lc.start === selectedDay && lc.id !== element.dataset.id
      ) && `Day #${selectedDay} already exists`;

    const notSorted =
      lightCycles
        .slice(
          0,
          lightCycles.findIndex((lc) => lc.id === element.dataset.id)
        )
        .some((lc) => lc.start > selectedDay) &&
      'Days must be in ascending order';

    if (isFirst && selectedDay > 0)
      element.setCustomValidity('The first day should be 0');
    else if (startTimeNotUnique) element.setCustomValidity(startTimeNotUnique);
    else if (notSorted) element.setCustomValidity(notSorted);
    else element.setCustomValidity('');
  };

  const handleDurationRef = (element: HTMLInputElement) => {
    if (isNil(element?.value)) {
      return;
    }
    const [hours, minutes] = element.value
      .replace(/(\d+)h (\d+)m/, '$1:$2')
      .split(':')
      .map(Number) as [number, number];
    const durationZero = hours === 0 && minutes === 0;
    element.setCustomValidity(
      durationZero ? 'Duration must be greater than 0' : ''
    );
  };

  const lastStartInputRef = useRef<HTMLInputElement>(null);

  return (
    <div
      ref={ref}
      {...props}
      className={cn(
        'flex w-full flex-col items-center gap-4 sm:max-w-[592px]',
        className
      )}
    >
      <h3 className="text-xl font-bold">{title ?? 'Light Cycles'}</h3>

      <p className="text-center">
        {subtitle ?? 'Set when your lights are on and off.'}
      </p>

      {namePlacement === 'top' && (
        <PresetName
          value={preset.name}
          onChange={(event) => handleChangePreset('name', event.target.value)}
        />
      )}

      <div
        className="mt-6 flex w-full flex-col items-start justify-center"
        role="table"
      >
        <div className="flex w-full flex-col gap-4">
          <div className="flex w-full flex-row gap-2 font-semibold">
            <div className="flex w-full justify-start" role="columnheader">
              Start
            </div>
            <div className="flex w-full justify-start" role="columnheader">
              On At
            </div>
            <div className="flex w-full justify-start" role="columnheader">
              Duration
            </div>
            <div className="min-w-9 p-0" />
          </div>
          <div className="flex flex-col gap-1">
            {lightCycles.map(({ id, start, onAt, duration }, index) => {
              const dayLabel = `Day #${start + 1}`;
              const startTimeNotUnique =
                lightCycles[index - 1] &&
                lightCycles[index - 1]!.start === start;
              const notSorted = lightCycles
                .slice(0, index)
                .some((setting) => setting.start > start);
              const firstDay = index === 0 && start > 0;
              const moreThanTotalDays = start > totalDays;
              const statuses = [
                firstDay && ['text-red-500', 'First day must be 1w 1d'],
                duration === 0 && [
                  'text-red-500',
                  'Duration must be greater than 0',
                ],
                startTimeNotUnique && [
                  'text-red-500',
                  'Start time must be unique',
                ],
                notSorted && [
                  'text-red-500',
                  'Days must be in ascending order',
                ],
                moreThanTotalDays && [
                  'text-orange-500',
                  `The growth cycle has ${totalDays} days but the last setting is defined for the day #${start}.`,
                ],
              ].filter(Boolean) as [string, string][];

              const week = Math.floor(start / 7);
              const day = start % 7;

              const durationHours = Math.floor(duration / 60);
              const durationMinutes = duration % 60;

              return (
                <div
                  role="row"
                  key={id}
                  className="flex flex-col gap-1 p-1 rounded-xs focused-row"
                >
                  <div className={'flex w-full p-0 gap-2 items-center'}>
                    <div className={'w-full p-0'}>
                      <TimePicker
                        ref={mergeRefs([
                          index === lightCycles.length - 1
                            ? lastStartInputRef
                            : null,
                          (element) =>
                            handleWeekAndDaysRef(element, index === 0),
                        ])}
                        aria-label={dayLabel}
                        data-id={id}
                        type="weeksAndDays"
                        firstValue={week}
                        secondValue={day}
                        firstMaxValue={99}
                        secondMaxValue={Math.min(totalDays, 7)}
                        onChange={handleChange(id, 'start')}
                        readOnly={index === 0 && start === 0}
                        disabled={index === 0 && start === 0}
                      />
                    </div>
                    <div className="w-full p-0">
                      <TimePicker
                        aria-label={`${dayLabel} start`}
                        firstValue={Number(onAt.split(':')[0])}
                        secondValue={Number(onAt.split(':')[1])}
                        onChange={handleChange(id, 'onAt')}
                        useNativeTimeInput={true}
                      />
                    </div>
                    <div className="w-full p-0">
                      <TimePicker
                        aria-label={`${dayLabel} duration`}
                        ref={handleDurationRef}
                        type="duration"
                        firstValue={durationHours}
                        secondValue={durationMinutes}
                        onChange={handleChange(id, 'duration')}
                      />
                    </div>
                    <div className="min-w-9 p-0">
                      {index === 0 && (
                        <Button variant="flat" size="icon" aria-hidden disabled>
                          <LockIcon />
                        </Button>
                      )}
                      {index !== 0 && (
                        <Button
                          variant="flat"
                          size="icon"
                          aria-label={`Remove ${dayLabel}`}
                          onClick={handleDelete(id)}
                        >
                          <GarbageIcon />
                        </Button>
                      )}
                    </div>
                  </div>

                  {statuses.filter(Boolean).map(([className, status]) => (
                    <div className="col-span-full" key={status}>
                      <StatusText className={cn('block px-1', className)}>
                        {status}
                      </StatusText>
                    </div>
                  ))}
                </div>
              );
            })}
          </div>
        </div>

        <div className="flex w-full flex-row justify-between py-2">
          <Button
            variant="flat"
            onClick={handleAddRow}
            disabled={disableAddButton}
          >
            Add more
          </Button>

          <Button variant="flat" onClick={handleClearAll}>
            Clear all
          </Button>
        </div>
      </div>

      {namePlacement === 'bottom' && (
        <PresetName
          value={preset.name}
          onChange={(event) => handleChangePreset('name', event.target.value)}
          helperText="For your convenience, we’ll save this data for your next growth cycle. Find it with ease by using a custom name."
        />
      )}
    </div>
  );
});
