import {
  useDeleteComment,
  useGetComments,
  useInsertComment,
  useMarkCommentAsRead,
  useUpdateComment,
} from 'api/discussion';
import { useAvailableMentions } from 'api/user';
import { Alert } from 'components/common/Alert/Alert';
import { Button, ButtonProps } from 'components/common/Button/Button';
import { Menu } from 'components/common/Menu';
import { Textarea } from 'components/common/Textarea/Textarea';
import { TextEditor } from 'components/common/TextEditor/TextEditor';
import { error, success } from 'components/common/Toast/Toast';
import { useAuth } from 'contexts/AuthProvider';
import { useDisclosure } from 'hooks/useDisclosure';
import { usePermissions } from 'hooks/usePermissions';
import { useZones } from 'hooks/useZones';
import { MoreVerticalIcon } from 'icons/MoreVerticalIcon';
import { LexicalEditor } from 'lexical';
import isNil from 'lodash.isnil';
import { SendHorizonalIcon } from 'lucide-react';
import { Ref, useCallback, useEffect, useRef, useState } from 'react';
import { InView } from 'react-intersection-observer';
import { TComment, TDiscussion, TMention } from 'shared/interfaces/discussion';
import { NEATLEAF_ORGANIZATION_CODE } from 'shared/interfaces/organization';
import { formatDateEEEMMMMdhmmaa } from 'shared/utils/date';
import { createEditorStateFromText } from 'shared/utils/textEditor';
import { v4 } from 'uuid';
import { useNavigateToDiscussion } from './hooks/useNavigateToDiscussion';

const COMMENT_MAX_CONTENT_LENGTH = 280;

type CommentProps = {
  inView?: boolean;
  comment?: TComment;
  availableMentions?: TMention[];
  onEdit?: (content: TComment['content']) => Promise<void>;
  onDelete?: () => void;
};

export type CommentThreadProps = {
  discussion: TDiscussion;
};

const Comment = ({
  inView,
  comment,
  availableMentions = [],
  onEdit,
  onDelete,
}: CommentProps) => {
  const [disabled, setDisabled] = useState(false);
  const { user } = useAuth();
  const userId = user?.id!;
  const { markCommentAsRead } = useMarkCommentAsRead();

  useEffect(() => {
    if (inView && comment && !comment.isRead) {
      markCommentAsRead(comment, userId);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [comment?.uid, inView]);

  const [editedContent, setEditedContent] = useState<string>(
    comment?.content.content || ''
  );
  const [isEditing, setIsEditing] = useState<boolean>(!comment);
  const handleSubmitComment = async () => {
    setDisabled(true);
    const content: TComment['content'] = Object.assign({}, comment?.content, {
      content: editedContent,
    });
    try {
      await onEdit?.(content);
      comment && setIsEditing(false);
      success(
        {
          content: 'Comment saved successfully',
        },
        {
          autoClose: 3000,
        }
      );
    } catch {
      error(
        {
          content: 'Failed to save comment',
        },
        {
          toastId: 'comment-failed',
        }
      );
    } finally {
      setDisabled(false);
    }
  };

  const discloseDeleteConfirmation = useDisclosure();

  const menuItems: ButtonProps[] = [];

  if (onEdit) {
    menuItems.push({
      children: 'Edit',
      onClick: () => setIsEditing(true),
      variant: 'secondary',
    });
  }

  if (onDelete) {
    menuItems.push({
      children: 'Delete',
      onClick: discloseDeleteConfirmation.open,
      variant: 'error',
    });
  }

  const textareaRef = useRef<HTMLTextAreaElement | LexicalEditor>(null);

  const handleCancel = () => {
    if (disabled) return;
    if (comment) {
      setIsEditing(false);
    } else {
      (textareaRef.current as Maybe<LexicalEditor>)?.setEditorState(
        createEditorStateFromText('')
      );
    }
  };

  return (
    <div className="flex flex-col gap-2 text-sm">
      {comment && !isEditing && (
        <div className="flex justify-between items-center">
          <div className="flex gap-2">
            <b>{comment.authorName}</b>
            <div className="text-neutral-400">
              {formatDateEEEMMMMdhmmaa(comment.createdAt!)}
            </div>
          </div>
          {menuItems.length > 0 && (
            <Menu
              items={menuItems}
              floatProps={{ flip: true }}
              button={
                <Button variant="secondary" size="icon" aria-label="More">
                  <MoreVerticalIcon />
                </Button>
              }
            />
          )}
        </div>
      )}
      {comment && !isEditing && (
        <TextEditor
          key={comment.content.content}
          initialEditorState={comment.content.content}
          editable={false}
          readMoreContentLength={COMMENT_MAX_CONTENT_LENGTH}
          availableMentions={availableMentions}
        />
      )}
      {isEditing && (
        <Textarea
          ref={textareaRef as Maybe<Ref<LexicalEditor>>}
          actionIcon={<SendHorizonalIcon size={20} />}
          disabled={disabled}
          aria-label="Add a comment"
          onClickAction={handleSubmitComment}
          onCancel={handleCancel}
          placeholder="Add a comment"
          value={editedContent}
          isLexicalEditor
          onChange={setEditedContent}
          availableMentions={availableMentions}
        />
      )}

      {discloseDeleteConfirmation.isOpen && (
        <Alert
          open={discloseDeleteConfirmation.isOpen}
          onCancel={discloseDeleteConfirmation.close}
          onConfirm={() => onDelete?.()}
        >
          Are you sure you wish to delete this comment?
        </Alert>
      )}
    </div>
  );
};

export function CommentThread({ discussion }: CommentThreadProps) {
  const { firstComment } = discussion;
  const { user, currentlySelectedOrganization, isNeatleafOrganizationMember } =
    useAuth();
  const organizationCode = currentlySelectedOrganization?.code!;
  const { zones } = useZones();
  const permissions = usePermissions();
  const userId = user?.id!;
  const { comments } = useGetComments({ discussion, userId, zones });
  const availableMentions = useAvailableMentions(discussion.zoneUid);
  const computeDiscussionLink = useNavigateToDiscussion(true);
  const { insert } = useInsertComment(userId);
  const { update } = useUpdateComment(userId);
  const { remove } = useDeleteComment(userId);
  const { markCommentAsRead } = useMarkCommentAsRead();
  const displyedComments = comments
    .filter(
      (comment) =>
        !comment.firstCommentInDiscussion && !isNil(comment.createdAt)
    )
    .sort((a, b) => a.createdAt!.valueOf() - b.createdAt!.valueOf());
  const handleInsert = useCallback(
    async (content: TComment['content']) => {
      try {
        await insert(
          {
            uid: v4(),
            discussionUid: discussion.uid,
            zoneUid: discussion.zoneUid,
            organizationCode,
            content,
            authorId: userId,
            authorOrganizationCode: isNeatleafOrganizationMember
              ? NEATLEAF_ORGANIZATION_CODE
              : organizationCode,
            firstCommentInDiscussion: false,
          },
          (await computeDiscussionLink(discussion)) as string
        );
      } catch (e) {
        console.error(e);
      }
    },
    [
      computeDiscussionLink,
      discussion,
      insert,
      isNeatleafOrganizationMember,
      organizationCode,
      userId,
    ]
  );
  const handleEdit = useCallback(
    (comment: TComment) => {
      if (permissions.comments.canUpdate && comment.authorId === userId) {
        return async (content: TComment['content']) => {
          try {
            await update(
              comment.uid,
              content,
              discussion.uid,
              comment.content,
              (await computeDiscussionLink(discussion)) as string
            );
          } catch (e) {
            console.error(e);
          }
        };
      }

      return undefined;
    },
    [
      computeDiscussionLink,
      discussion,
      permissions.comments.canUpdate,
      update,
      userId,
    ]
  );
  const handleDelete = useCallback(
    (comment: TComment) => {
      if (permissions.comments.canDelete && comment.authorId === userId) {
        return () => remove(comment.uid, discussion.uid);
      }

      return undefined;
    },
    [discussion.uid, permissions.comments.canDelete, remove, userId]
  );

  useEffect(() => {
    if (firstComment && !firstComment.isRead) {
      markCommentAsRead(firstComment, userId);
    }

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstComment?.uid]);

  return (
    <div className="flex flex-col gap-4">
      {displyedComments.map((comment) => (
        <InView key={comment.uid}>
          {({ ref, inView }) => (
            <div ref={ref}>
              <Comment
                availableMentions={availableMentions}
                inView={inView}
                comment={comment}
                onEdit={handleEdit(comment)}
                onDelete={handleDelete(comment)}
              />
            </div>
          )}
        </InView>
      ))}

      {permissions.comments.canCreate && (
        <Comment
          key={displyedComments.length}
          onEdit={handleInsert}
          availableMentions={availableMentions}
        />
      )}
    </div>
  );
}
