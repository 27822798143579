import { Button } from 'components/common/Button/Button';
import { CenteredLoader } from 'components/common/CenteredLoader';
import { TimelineRange } from 'components/common/TimelineRange/TimelineRange';
import { Wrap } from 'components/common/Wrap/Wrap';
import { DiscussionBoundingBoxAnnotations } from 'components/discussions/DiscussionBoundingBoxAnnotations';
import { useImageFeedLabelsCounts } from 'components/image_feed/hooks/useImageFeedLabelsCounts';
import { useImageSizeIndexMap } from 'components/image_feed/hooks/useImageSizeIndexMap';
import {
  getCoordinatesLabel,
  getNextImagePostition,
} from 'components/image_feed/utils';
import {
  ArrowButtonList,
  ImageArrowButton,
} from 'components/image_section_view/ImageArrowButtons';
import { ImageCapture } from 'components/image_section_view/ImageCapture';
import {
  useImageFeedURL,
  useZoneDetailsPageURL,
} from 'contexts/URLStoreProvider/URLStoreProvider';
import { useCurrentZone } from 'hooks/useCurrentZone';
import { usePermissions } from 'hooks/usePermissions';
import { CloseIcon } from 'icons/CloseIcon';
import { CommentIcon } from 'icons/CommentIcon';
import isNil from 'lodash.isnil';
import {
  FC,
  ReactNode,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import {
  ReactZoomPanPinchProps,
  ReactZoomPanPinchRef,
  TransformComponent,
  TransformWrapper,
} from 'react-zoom-pan-pinch';
import { TDiscussionMetadata } from 'shared/interfaces/discussion';
import {
  EImageTypes,
  TImagesGrid,
  TScaleAndPosition,
} from 'shared/interfaces/image';
import { EEventKeyCodes } from 'shared/interfaces/keys';
import { cn } from 'shared/utils/cn';
import { clamp } from 'shared/utils/miscellaneous';
import { useDebouncedCallback } from 'use-debounce';
import { useDrawBoundingBoxController } from '../../hooks/useDrawBoundingBoxController';
import { useGetCurrentAndNextSectionCaptures } from './hooks/useGetCurrentAndNextSectionCaptures';
import { useMeasurementBoundingBoxes } from './hooks/useMeasurementBoundingBoxes';
import { getImageScaleRate } from './utils';

const TRANSFORM_MIN_SCALE = 0.1;
const INITIAL_SCALE = TRANSFORM_MIN_SCALE;
const INITIAL_POSITION_X = 0;
const INITIAL_POSITION_Y = 0;
const IMAGE_POSITION_AUTOMATIC_ADJUSTMENT_MILLISECONDS = 500;

const INITIAL_SCALE_AND_POSITION: TScaleAndPosition = {
  scale: INITIAL_SCALE,
  positionX: INITIAL_POSITION_X,
  positionY: INITIAL_POSITION_Y,
};

interface IImageSectionViewProps {
  /** The grid images information. */
  imagesGrid: TImagesGrid;
  /** The image size. */
  imageSize: TSize;
  /** The image type switch component. */
  imageTypeSwitch?: ReactNode;
  /** The value which shows allowance of NDVI image */
  shouldDisplayNDVIImages: boolean;
  /** The size of the grid. */
  gridSize: TGridSize;
  /** The zone that contains current section. */
  onChangeImageType: (type: EImageTypes) => void;
  /** Callback to be called when the position changes. */
  onChangeSection: (position: TPosition) => void;
  /** Called whenever a new date is selected. Required. */
  onSelectDate: (date: Date) => void;
}

export const ImageSectionView: FC<IImageSectionViewProps> = ({
  imagesGrid,
  imageSize,
  gridSize,
  onChangeSection,
  onSelectDate,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const transformRef = useRef<ReactZoomPanPinchRef>(null);
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const [scaleAndPosition, setScaleAndPosition] = useState<TScaleAndPosition>(
    INITIAL_SCALE_AND_POSITION
  );
  const [isInteracting, setIsInteracting] = useState(false);
  const scale = scaleAndPosition?.scale ?? INITIAL_SCALE;
  const [sortedImageUrlIndex, setImageIndex] = useState(0);
  const { zoneTimeZone } = useCurrentZone();
  const { discussions } = usePermissions();
  const { updateImageSizeIndexMap, getCurrentImageSizeIndex } =
    useImageSizeIndexMap();

  const {
    sectionPosition: position,
    imageLabelCode,
    scale: urlScale,
    x,
    y,
    zoneId,
    imageType,
    showComments,
    setShowComments,
    setImageViewTypeToGrid,
    setSingleImagePositionAndScale,
  } = useImageFeedURL();

  const { getRangeStartTime, getRangeEndTime, getMeasurementRunStartTime } =
    useZoneDetailsPageURL();

  if (zoneId === undefined) throw new Error('Zone id is undefined');
  if (position === undefined) throw new Error('Position is undefined');

  const { captures } = useGetCurrentAndNextSectionCaptures({
    startTime: getRangeStartTime(zoneTimeZone),
    endTime: getRangeEndTime(zoneTimeZone),
    gridSize,
    position,
    zoneId,
    imageType,
  });

  const timelineDates = useMemo(
    () => captures.map(({ time }) => time),
    [captures]
  );

  const measurementRunStartTime = getMeasurementRunStartTime(zoneTimeZone);

  const captureIndex = useMemo(() => {
    const fallbackIndex = captures.length - 1;
    if (!measurementRunStartTime) return fallbackIndex;
    const index = captures.findIndex((mr) => {
      return mr.time.valueOf() >= measurementRunStartTime;
    });
    return index !== -1 ? index : fallbackIndex;
  }, [captures, measurementRunStartTime]);

  const handleImageFeedBack = () => {
    setImageViewTypeToGrid();
  };

  const currentCapture = captures[captureIndex];

  const imageSrc = currentCapture?.sortedImageUrls[sortedImageUrlIndex] ?? '';

  const { labelsCountsByMeasurementId } = useImageFeedLabelsCounts();

  const labelsByCategory =
    labelsCountsByMeasurementId[currentCapture?.measurementId ?? -1] || [];

  const boundingBoxes = useMeasurementBoundingBoxes({
    labelsByCategory,
    imageLabelCode,
  });

  useLayoutEffect(() => {
    if (!isNil(currentCapture?.measurementRunId)) {
      setIsImageLoaded(() => false);
      setImageIndex(() =>
        Math.max(
          0,
          getCurrentImageSizeIndex(
            currentCapture!.measurementRunId!,
            gridSize,
            position.y,
            position.x
          )
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCapture?.measurementId]);

  useHotkeys(
    EEventKeyCodes.SPACE,
    (event) => {
      event.preventDefault();
      event.stopPropagation();
      onChangeSection(getNextImagePostition(position, gridSize));
    },
    [position, gridSize]
  );

  const handleImageLoaded = () => {
    const viewSize: TSize = {
      height: containerRef.current?.clientHeight ?? 0,
      width: containerRef.current?.clientWidth ?? 0,
    };
    if (!isImageLoaded) {
      if (
        urlScale !== undefined &&
        x !== undefined &&
        y !== undefined &&
        (urlScale !== INITIAL_SCALE ||
          x !== INITIAL_POSITION_X ||
          y !== INITIAL_POSITION_Y)
      ) {
        transformRef?.current?.setTransform(x!, y!, urlScale!, 0);
      } else {
        transformRef?.current?.centerView(
          getImageScaleRate(viewSize, imageSize),
          0
        );
      }
    }
    setIsImageLoaded(() => true);
    setImageIndex((prev) => {
      if (
        isNil(currentCapture) ||
        currentCapture.sortedImageUrls.length === 0
      ) {
        return prev;
      }
      if (!isNil(currentCapture.measurementRunId)) {
        updateImageSizeIndexMap(
          currentCapture.measurementRunId!,
          prev,
          gridSize,
          position.y,
          position.x
        );
      }
      return clamp(prev + 1, 0, currentCapture?.sortedImageUrls.length - 1);
    });
  };

  const [isDrawAnnotationMode, setIsDrawAnnotationMode] = useState(false);
  const handleDrawEnd = useCallback(() => {
    setIsDrawAnnotationMode(false);
  }, []);
  const drawBoundingBoxController =
    useDrawBoundingBoxController<TDiscussionMetadata>({
      scaleAndPosition,
      drawingMode: isDrawAnnotationMode,
      onDrawEnd: () => handleDrawEnd(),
    });

  const handleOnClickOverlay = useCallback(
    (element: HTMLElement, isAnnotation = false) => {
      setIsDrawAnnotationMode(false);
      if (!isAnnotation) drawBoundingBoxController?.clear();
      if (isInteracting) return;
      if (!transformRef.current || !element || !containerRef.current) return;

      const containerRect = containerRef.current.getBoundingClientRect();
      const elementRect = element.getBoundingClientRect();

      // Calculate max zoom that does not exceed the element's size ratio in the container
      const widthRatio = containerRect.width / (elementRect.width / scale);
      const heightRatio = containerRect.height / (elementRect.height / scale);
      const elementMaxZoom = Math.min(widthRatio, heightRatio) * 0.9;

      // Determine the best scale not exceeding the element's own dimensions
      const safeScale = Math.min(Math.max(3, scale), elementMaxZoom);
      requestAnimationFrame(() => {
        transformRef.current?.zoomToElement(element, safeScale);
      });
    },
    [scale, isInteracting, drawBoundingBoxController]
  );

  const handleOnTransformed: ReactZoomPanPinchProps['onTransformed'] = ({
    state: { scale, positionX, positionY },
  }) => {
    setScaleAndPosition({
      scale,
      positionX,
      positionY,
    });
  };

  const handlePanning = (ref: ReactZoomPanPinchRef) => {
    if (!isInteracting && ref.instance.lastMousePosition !== null)
      setIsInteracting(true);
  };

  const handleStop = useDebouncedCallback((ref: ReactZoomPanPinchRef) => {
    setSingleImagePositionAndScale(
      {
        x: ref.state.positionX,
        y: ref.state.positionY,
        scale: ref.state.scale,
      },
      { replace: true }
    );
    setIsInteracting(false);
    // Automatic adjustment likely finished after debounce timeout
  }, IMAGE_POSITION_AUTOMATIC_ADJUSTMENT_MILLISECONDS);

  useEffect(() => {
    return () => {
      handleStop.cancel();
    };
  }, [handleStop]);

  return (
    <>
      <div
        data-testid="ImageSectionView"
        className={cn(
          'relative max-w-full flex flex-1 overflow-auto',
          'max-md:flex-col'
        )}
      >
        <div className="relative w-full flex-1 overflow-hidden">
          {!isImageLoaded && (
            <div
              className="absolute top-0 left-0 bottom-0 right-0 bg-no-repeat bg-center bg-contain"
              style={{
                backgroundImage: `url(${currentCapture?.minImageUrl})`,
                height: '100%',
                width: 'fit-content',
              }}
            />
          )}
          {!isImageLoaded && <CenteredLoader />}

          <img
            alt="Capture"
            className="hidden"
            height={'100%'}
            src={imageSrc}
            onLoad={handleImageLoaded}
          />

          <div
            ref={containerRef}
            className={cn(
              'relative flex h-full justify-center',
              '[&_.react-transform-wrapper]:visible',
              '[&_.react-transform-wrapper]:w-fit',
              '[&_.react-transform-wrapper]:m-auto',
              '[&_.react-transform-wrapper]:h-full',
              '[&_.react-transform-wrapper_.react-transform-component]:w-fit',
              '[&_.react-transform-wrapper_.react-transform-component]:h-fit'
            )}
          >
            {currentCapture?.measurementId && (
              <TransformWrapper
                minScale={TRANSFORM_MIN_SCALE}
                doubleClick={{ disabled: true }}
                ref={transformRef}
                wheel={{ step: 0.1 }}
                onZoomStop={handleStop}
                onPanningStop={handleStop}
                onPanning={handlePanning}
                onWheelStop={handleStop}
                onPinchingStop={handleStop}
                onTransformed={handleOnTransformed}
                panning={{ disabled: isDrawAnnotationMode }}
                pinch={{ disabled: isDrawAnnotationMode }}
              >
                <TransformComponent>
                  <Wrap
                    with={(children) => (
                      <DiscussionBoundingBoxAnnotations
                        scale={scaleAndPosition.scale}
                        position={position}
                        annotationInfo={{
                          startTime: currentCapture.time,
                          endTime: currentCapture.time,
                          id: currentCapture.measurementId,
                          type: 'single_image_annotation',
                        }}
                        resolution={imageSize}
                        drawController={drawBoundingBoxController}
                        onSelectionChange={(elementRef) => {
                          elementRef.current &&
                            handleOnClickOverlay(elementRef.current, true);
                        }}
                      >
                        {children}
                      </DiscussionBoundingBoxAnnotations>
                    )}
                    condition={discussions.canView && showComments}
                  >
                    <ImageCapture
                      alt="Capture"
                      boundingBoxes={boundingBoxes}
                      imageSrc={imageSrc}
                      imageSize={imageSize}
                      scale={scale}
                      onClickOverlay={handleOnClickOverlay}
                    />
                  </Wrap>
                </TransformComponent>
              </TransformWrapper>
            )}

            {Object.values(ArrowButtonList).map((direction) => (
              <ImageArrowButton
                key={`arrow-${direction}`}
                direction={direction}
                gridSize={gridSize}
                position={position}
                onChangeSection={onChangeSection}
                imagesGrid={imagesGrid}
              />
            ))}
          </div>
        </div>

        {discussions.canCreate && (
          <Button
            disabled={!!drawBoundingBoxController?.disabled}
            onClick={() => {
              setIsDrawAnnotationMode(!isDrawAnnotationMode);
              setShowComments(true);
            }}
            variant={isDrawAnnotationMode ? 'secondary' : 'primary'}
            size="responsive"
            className="absolute top-0 right-0"
            leadingIcon={isDrawAnnotationMode ? <CloseIcon /> : <CommentIcon />}
          >
            {isDrawAnnotationMode ? 'Close' : 'Comment'}
          </Button>
        )}

        <Button
          variant="secondary"
          aria-label="Back"
          leadingIcon={<CloseIcon />}
          className="absolute top-2 left-2"
          onClick={handleImageFeedBack}
        >
          {getCoordinatesLabel(position)}
        </Button>
      </div>

      <TimelineRange dates={timelineDates} onSelectDate={onSelectDate} />
    </>
  );
};
