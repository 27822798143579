import { Chart, ChartProps } from 'components/common/Chart/Chart';
import { ComponentProps, forwardRef, useMemo } from 'react';
import { SignalMeasurementsType } from 'shared/interfaces/measurement';
import { smartRound } from 'shared/utils/miscellaneous';

interface SummaryMetricsProps extends ComponentProps<'div'> {
  data: SignalMeasurementsType;
}

export const SummaryMetrics = forwardRef<HTMLDivElement, SummaryMetricsProps>(
  function SummaryMetrics({ data, ...props }, ref) {
    const signals = Array.from(data.keys());
    const chartAriaLabel = `Summary metrics for ${signals.map(({ label }) => label).join()}`;
    const yAxis = useMemo(
      function computeYAxis() {
        if (signals.length === 0) {
          return [{ title: undefined }];
        }

        return signals.reduce<Highcharts.YAxisOptions[]>((axes, signal) => {
          const exists = axes.some(({ id }) => id === signal.unit);

          if (!exists) {
            axes.push({
              id: signal.unit,
              reversed: signal.reversedYAxis,
              visible: false,
            });
          }

          return axes;
        }, []);
      },
      [signals]
    );
    const { boxPlotSeries, annotations } = useMemo<{
      boxPlotSeries: Highcharts.SeriesBoxplotOptions[];
      annotations: Highcharts.AnnotationsOptions[];
    }>(
      function computeBoxPlotSeries() {
        const availableData = Array.from(data.entries()).toSorted(([a], [b]) =>
          (a.group ?? '').localeCompare(b.group ?? '')
        );
        const boxPlotSeries: Highcharts.SeriesBoxplotOptions[] = [];
        const annotations: Highcharts.AnnotationsOptions[] = [];

        let index = 0;
        for (const [signal, values] of availableData) {
          // Ensure an unique name
          const name = availableData.some(
            ([{ type, label }]) =>
              label === signal.label && type !== signal.type
          )
            ? `${signal.label} (${signal.group})`
            : signal.label;
          const point = {
            ...measurementsToPoint(values),
            id: `point-${signal.type}`,
            name: signal.type,
          };

          // Series
          const series: Highcharts.SeriesBoxplotOptions = {
            id: signal.type,
            name,
            type: 'boxplot',
            yAxis: signal.unit,
            data: [point],
            className: `${signal.style?.svg} stroke-2`,
            tooltip: {
              valueDecimals: 2,
              valueSuffix: ` ${signal.unit}`,
            },
          };

          boxPlotSeries.push(series);

          // Only add annotation labels when low, median, high are not the same
          if (point.low !== point.median && point.median !== point.high) {
            // Annotation labels
            const annotationPoint = {
              xAxis: 0,
              yAxis: signal.unit,
              x: index,
            };
            const x = (() => {
              // is single
              if (availableData.length === 1) {
                return undefined;
              }

              // the first
              if (index === 0) {
                return -10;
              }
              // the last
              if (index === availableData.length - 1) {
                return 10;
              }
            })();

            annotations.push({
              draggable: '',

              labelOptions: {
                overflow: 'allow',
                shape: 'rect',
                padding: 2,
                borderRadius: 8,
                className: `${signal.style?.svg} cursor-auto`,
                verticalAlign: 'top',
                x,
              },
              labels: [
                {
                  text: labelText(smartRound(point.high!)),
                  point: {
                    ...annotationPoint,
                    y: point.high!,
                  },
                },
                {
                  text: labelText(
                    `${smartRound(point.median!)} ${signal.unit}`
                  ),
                  point: {
                    ...annotationPoint,
                    y: point.median!,
                  },
                },
                {
                  text: labelText(smartRound(point.low!)),
                  point: {
                    ...annotationPoint,
                    y: point.low!,
                  },
                },
              ],
            });
          }

          index++;
        }

        return { boxPlotSeries, annotations };
      },
      [data]
    );
    const options = useMemo<ChartProps['options']>(
      function computeOptions() {
        return {
          accessibility: {
            description: chartAriaLabel,
          },
          chart: {
            type: 'boxplot',
            marginBottom: 35,
            marginLeft: 30,
            marginRight: 30,
          },
          plotOptions: {
            series: {
              enableMouseTracking: true,
            },
            boxplot: {
              pointWidth: 6,
            },
          },
          series: boxPlotSeries,
          annotations,
          tooltip: {
            enabled: true,
            followPointer: true,
            stickOnContact: true,
            snap: 20,
            formatter: function () {
              const { low, median, high } = this.point.options;

              return [
                `<b>${this.series.name} (${this.series.yAxis.options.id})</b><br/>`,
                `Maximum at <b>${smartRound(high!)}</b><br/>`,
                `Median at <b>${smartRound(median!)}</b><br/>`,
                `Minimum at <b>${smartRound(low!)}</b>`,
              ];
            },
          },
          xAxis: {
            type: 'category',
            visible: false,
          },
          yAxis,
        };
      },
      [annotations, boxPlotSeries, chartAriaLabel, yAxis]
    );

    return (
      <div ref={ref} {...props} style={{ width: signals.length * 60 }}>
        <Chart
          className="w-full h-full"
          aria-label={chartAriaLabel}
          options={options}
        />
      </div>
    );
  }
);

const labelText = (text: string | number) =>
  `<span style="visibility: hidden;">. </span>${String(text)}<span style="visibility: hidden;"> .</span>`;

function measurementsToPoint(
  data: [number, number][]
): Highcharts.PointOptionsObject {
  if (data.length === 0) {
    return {
      low: 0,
      q1: 0,
      median: 0,
      q3: 0,
      high: 0,
    };
  }

  // Sort data
  const sorted = data.map(([_, value]) => value).toSorted((a, b) => a - b);

  // Helper function to calculate the median
  const getMedian = (list: number[]): number => {
    const mid = Math.floor(list.length / 2);
    return list.length % 2 === 0
      ? (list[mid - 1]! + list[mid]!) / 2
      : list[mid]!;
  };

  // Calculate Q1 (lower quartile) and Q3 (upper quartile)
  const lowerHalf = sorted.slice(0, Math.floor(sorted.length / 2));
  const upperHalf = sorted.slice(Math.ceil(sorted.length / 2));

  return {
    low: sorted[0],
    q1: getMedian(lowerHalf),
    median: getMedian(sorted),
    q3: getMedian(upperHalf),
    high: sorted.at(-1),
  };
}
