import { createStyles, makeStyles } from '@material-ui/core';
import { Button } from 'components/common/Button/Button';
import { ConfirmDialog } from 'components/common/ConfirmDialog';
import { Link } from 'components/common/Link';
import { Switch } from 'components/common/Switch/Switch';
import { TextInputFormField } from 'components/common/TextInputFormField';
import { Typography } from 'components/common/Typography';
import { UserRoleSelector } from 'components/user/UserRoleSelector';
import { useAuth } from 'contexts/AuthProvider';
import { FC, useCallback, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { ERoutePath, PATH_PATTERNS } from 'shared/constants/url';
import { EUserRole } from 'shared/interfaces/user';

const useStyles = makeStyles(({ spacing, breakpoints }) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      gap: spacing(5),
    },

    fieldsList: {
      display: 'flex',
      flexDirection: 'column',
      gap: spacing(5),
      maxWidth: breakpoints.values.xs,
    },

    statusField: {
      display: 'flex',
      justifyContent: 'space-between',
    },

    actions: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row',
      gap: spacing(1),
    },

    actionButton: {
      minWidth: 200,
      flex: 1,
    },

    switchWrapper: {
      display: 'flex',
      gap: spacing(1),
      alignItems: 'center',
    },
  })
);

export interface IEditProfileForm {
  active: boolean;
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  roles: string[];
}
const isValidEmail = (email: string): boolean =>
  // eslint-disable-next-line no-useless-escape
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    email
  );

const isValidPhoneNumber = (phoneNumber: string): boolean => {
  return phoneNumber.length === 10 && /^[0-9]+$/.test(phoneNumber);
};

export interface IEditProfileFormProps {
  /** The id of the user clicked */
  userId?: string;
  /** The default values of edit profile form. */
  defaultValues: IEditProfileForm;
  /** `true` if the email field is disabled, `false` otherwise. */
  disabledEmail?: boolean;
  /** Callback to be called when the user cancels editing the form. */
  onCancel?: (e: React.MouseEvent) => void;
  /** Callback to be called when the user clicks the change your password button. */
  onClickChangePassword?: (e: React.MouseEvent) => void;
  /** Callback to be called when the user closes the success message dialog. */
  onCloseSuccessMessage?: VoidFunction;
  /** Callback to be called when the user submits the form. */
  onSubmit: (form: IEditProfileForm) => void;
  /** `true` to show the roles selector, `false` otherwise. */
  showRole?: boolean;
  /** `true` to show the status selector, `false` otherwise. */
  showStatus?: boolean;
  /** The submit button text. */
  submitButtonText?: string;
  /** The success message after submit. */
  successMessage?: string;
}

export const EditProfileForm: FC<IEditProfileFormProps> = ({
  userId,
  defaultValues,
  disabledEmail = true,
  onCancel,
  onClickChangePassword,
  onCloseSuccessMessage,
  onSubmit,
  showRole = false,
  showStatus = false,
  submitButtonText = 'Update',
  successMessage = 'Profile updated!',
}) => {
  const classes = useStyles();

  const { user } = useAuth();
  const clientUserId = user?.id;
  const [showFormError, setShowFormError] = useState(false);
  const [error, setError] = useState<Nullable<string>>(null);
  const [showChangedDialog, setShowChangedDialog] = useState(false);

  const methods = useForm<IEditProfileForm>({
    defaultValues,
  });
  const { watch, setValue, handleSubmit } = methods;

  const roles = watch('roles');
  const active = watch('active');
  const phoneNumber = watch('phoneNumber');
  const email = watch('email');

  const isPhoneNumberValid = isValidPhoneNumber(phoneNumber);
  const isEmailValid = isValidEmail(email);
  const emailFieldHasError = !isEmailValid && showFormError;
  const phoneFieldHasError = !isPhoneNumberValid && showFormError;

  const handleSubmitForm = handleSubmit(async (values: IEditProfileForm) => {
    const isValid =
      isValidPhoneNumber(values.phoneNumber) && isValidEmail(values.email);

    if (!isValid) {
      setShowFormError(true);
      return;
    }

    try {
      setShowFormError(false);
      await onSubmit(values);
      setShowChangedDialog(true);
    } catch (error) {
      setError((error as Error).message);
    }
  });

  const handleCloseConfirmDialog = useCallback(() => {
    setShowChangedDialog(false);
    onCloseSuccessMessage?.();
  }, [onCloseSuccessMessage]);

  const handleCloseErrorDialog = useCallback(() => {
    setError(null);
  }, []);

  const handleChangeRole = useCallback(
    (roles: string[]) => {
      setValue('roles', roles);
    },
    [setValue]
  );

  const handleChangeStatus = useCallback(
    (active: boolean) => {
      setValue('active', active);
    },
    [setValue]
  );

  useEffect(() => {
    handleChangeRole([...roles, EUserRole.CULTIVATOR]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FormProvider {...methods}>
      <form role="form" onSubmit={handleSubmitForm}>
        <div className={classes.container}>
          {showRole && (
            <UserRoleSelector roles={roles} onChange={handleChangeRole} />
          )}

          <div className={classes.fieldsList}>
            <TextInputFormField
              fullWidth
              name="firstName"
              label="First Name"
              required
            />
            <TextInputFormField
              fullWidth
              name="lastName"
              label="Last Name"
              required
            />
            <TextInputFormField
              fullWidth
              name="phoneNumber"
              label="Phone"
              helperText={
                phoneFieldHasError ? 'US phone number (10 digits)' : undefined
              }
              error={phoneFieldHasError}
              required
            />
            <TextInputFormField
              disabled={disabledEmail}
              fullWidth
              name="email"
              label="Email"
              helperText={emailFieldHasError ? 'Email invalid' : undefined}
              error={emailFieldHasError}
              required
            />

            {showStatus && Number(userId) !== clientUserId && (
              <div className={classes.statusField}>
                <Typography color="gray" variant="body1">
                  Status
                </Typography>

                <div className={classes.switchWrapper}>
                  <Switch
                    checked={active}
                    onChange={handleChangeStatus}
                    label="Active"
                    labelPlacement="left"
                    labelClassName="text-gray-500 text-base"
                  />
                </div>
              </div>
            )}

            {onClickChangePassword && (
              <div>
                <Typography variant="body2">
                  Click the following link to{' '}
                  <Link
                    to={PATH_PATTERNS[ERoutePath.SETTINGS_EDIT_PROFILE]}
                    text="change your password"
                    onClick={onClickChangePassword}
                  />
                  .
                </Typography>
              </div>
            )}

            <div className={classes.actions}>
              {onCancel ? (
                <Button
                  variant="secondary"
                  className={classes.actionButton}
                  type="button"
                  onClick={onCancel}
                >
                  Cancel
                </Button>
              ) : null}

              <Button className={classes.actionButton} type="submit">
                {submitButtonText}
              </Button>
            </div>
          </div>
        </div>
      </form>

      {showChangedDialog ? (
        <ConfirmDialog
          title={successMessage}
          open
          onClose={handleCloseConfirmDialog}
        />
      ) : null}

      {error ? (
        <ConfirmDialog
          message={error}
          open
          variant="error"
          onClose={handleCloseErrorDialog}
        />
      ) : null}
    </FormProvider>
  );
};
