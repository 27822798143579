import { FC, useEffect, useRef } from 'react';
import { cn } from 'shared/utils/cn';

interface ITab {
  /** The tab id. */
  id: string;
  /** The tab label. */
  label: string;
  /** Whether the tab is disabled*/
  disabled?: boolean;
}

interface ITabGroupProps {
  /** The class to be applied. */
  className?: string;
  /** The tabs to be displayed in the group. */
  tabs: ITab[];
  /** The selected tab. */
  value: Nullable<string>;
  /** The callback to be called when current tab changes. */
  onChange: (tab: string) => void;
  /** If yes, scrolls the current tab into view when clicked */
  shouldScrollTabIntoView?: boolean;
}

export const TabGroup: FC<ITabGroupProps> = ({
  className,
  children,
  shouldScrollTabIntoView = true,
  tabs,
  value,
  onChange,
}) => {
  const ref = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (ref.current && shouldScrollTabIntoView) {
      ref.current.scrollIntoView({
        behavior: 'smooth',
        inline: 'center',
        block: 'nearest',
      });
    }
  }, [shouldScrollTabIntoView, value]);

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (!ref.current?.contains(document.activeElement)) return;

      if (e.key === 'ArrowLeft' || e.key === 'ArrowRight') {
        const currentIndex = tabs.findIndex((tab) => tab.id === value);
        const nextIndex =
          e.key === 'ArrowLeft' ? currentIndex - 1 : currentIndex + 1;
        const nextTab = tabs[(nextIndex + tabs.length) % tabs.length];
        if (nextTab) {
          onChange(nextTab.id);
          ref.current?.focus();
        }
      }
    };
    const handleKeyDownEvent = (e: KeyboardEvent) => {
      if (e.key === 'ArrowLeft' || e.key === 'ArrowRight') {
        handleKeyDown(e);
      }
    };
    document.addEventListener('keydown', handleKeyDownEvent);
    return () => {
      document.removeEventListener('keydown', handleKeyDownEvent);
    };
  }, [onChange, tabs, value]);

  return (
    <div
      className={cn(
        'flex h-full w-fit max-w-full items-center justify-between',
        'overflow-hidden overflow-x-auto no-scrollbar',
        className
      )}
      role="tablist"
    >
      {tabs.map(({ id, label, disabled }) => (
        <div
          key={id}
          className={cn(
            'h-full px-3 flex-shrink-0 rounded-xs hover:bg-sand-500 active:bg-sand-600',
            'has-[:focus]:outline outline-[1.5px] outline-orange-600 -outline-offset-[1.5px]',
            disabled && 'hover:bg-transparent active:bg-transparent'
          )}
        >
          <button
            ref={value === id ? ref : undefined}
            tabIndex={value === id ? 0 : -1}
            className={cn(
              'cursor-pointer py-2 text-neutral-900 h-10 border-b-[1.5px] border-transparent',
              'disabled:text-gray-400 disabled:cursor-default',
              'outline-none',
              value === id && 'border-orange-500'
            )}
            aria-selected={value === id}
            id={id}
            role="tab"
            disabled={disabled}
            onClick={() => onChange(id)}
          >
            {label}
          </button>
        </div>
      ))}
      {children}
    </div>
  );
};
