import {
  ComponentPropsWithoutRef,
  ReactElement,
  cloneElement,
  forwardRef,
  useMemo,
} from 'react';
import { cn } from 'shared/utils/cn';
import { v4 as uuidv4 } from 'uuid';

interface CardButtonProps extends ComponentPropsWithoutRef<'button'> {
  icon: ReactElement<HTMLElement>;
  title: string;
  subtitle: string;
}

export const CardButton = forwardRef<HTMLButtonElement, CardButtonProps>(
  function CardButton({ icon, title, subtitle, className, ...props }, ref) {
    const titleId = useMemo(() => uuidv4(), []);
    return (
      <button
        ref={ref}
        {...props}
        aria-labelledby={titleId}
        className={cn(
          'group/card py-5 px-6 flex gap-4 text-left items-center',
          'rounded-sm bg-white',
          'md:py-6 md:px-9 md:flex-col-reverse md:text-center md:rounded-xl',
          'outline-[1.5px] outline-orange-600 -outline-offset-[1.5px]',
          'hover:bg-sand-300 active:bg-sand-500',
          'disabled:bg-white disabled:text-gray-400',
          className
        )}
      >
        <div className="w-full flex flex-col justify-between md:gap-1">
          <p id={titleId} className="font-semibold md:text-lg">
            {title}
          </p>
          <p className="text-sm md:text-base">{subtitle}</p>
        </div>
        <div>
          {cloneElement(icon, {
            ...icon.props,
            className: cn(
              'h-6 w-6 mb-auto md:mb-8 md:h-16 md:w-16',
              'group-disabled/card:text-gray-400',
              icon.props.className
            ),
          })}
        </div>
      </button>
    );
  }
);
